import { Dispatch, MutableRefObject, SetStateAction } from "react";
import { TradeFlexLayoutComponentProps } from "@gemini-ui/pages/ActiveTrader/Spot/TradeLayout/TradeFlexLayout";
import { SubLayoutType } from "@gemini-ui/pages/ActiveTrader/Spot/TradeLayout/TradeFlexLayout/constants";
import { StoredLayout } from "@gemini-ui/pages/ActiveTrader/Spot/TradeLayout/TradeFlexLayout/LayoutCustomization/constants";
import { LayoutModelRefs } from "@gemini-ui/pages/ActiveTrader/Spot/TradeLayout/TradeFlexLayout/LayoutCustomization/hooks/useLayoutModelRefs";
import { LayoutMarket } from "@gemini-ui/pages/ActiveTrader/Spot/TradeLayout/TradeFlexLayout/types";

export type UseCustomLayoutOrderFormEventsProps = {
  tradingViewChartInLayout: boolean;
  refs: LayoutModelRefs;
  primaryPairDetail: TradeFlexLayoutComponentProps["primaryPairDetail"];
  secondaryPairDetail: TradeFlexLayoutComponentProps["secondaryPairDetail"];
  isDerivativesAcct: TradeFlexLayoutComponentProps["isDerivativesAcct"];
  layout: StoredLayout;
  isLayoutMenuOpen: boolean;
  setTradingViewChartInLayout: Dispatch<SetStateAction<boolean>>;
};

export type RefState = {
  primary: boolean;
  secondary: boolean;
};

export type LayoutType = SubLayoutType;

export type UseEmitOrderFormEventsProps = Omit<UseCustomLayoutOrderFormEventsProps, "isLayoutMenuOpen"> & {
  chartLoadedRef: MutableRefObject<RefState>;
  externalOrderFormCreatedRef: MutableRefObject<RefState>;
  chartExpandedRef: MutableRefObject<RefState>;
};

export type UseInitOrderFormListenersProps = UseCustomLayoutOrderFormEventsProps & {
  chartLoadedRef: MutableRefObject<RefState>;
  chartExpandedRef: MutableRefObject<RefState>;
  externalOrderFormCreatedRef: MutableRefObject<RefState>;
};

type CustomEvent<T extends string = string> = {
  type: T;
  pair: string;
};

export const GET_LAYOUT_MENU_OPEN_STATE = "getLayoutMenuOpenState";

interface GetLayoutMenuOpenState {
  type: typeof GET_LAYOUT_MENU_OPEN_STATE;
  layoutMarket: LayoutMarket;
}

export const IS_TAB_VISIBLE = "isTabVisible";

interface IsTabVisible extends CustomEvent {
  type: typeof IS_TAB_VISIBLE;
  tabId: string;
}

export const CHART_READY = "chartReady";

interface ChartReady extends CustomEvent {
  type: typeof CHART_READY;
  layout: SubLayoutType;
}

export const CAN_LOAD_ORDER_FORM = "canLoadOrderForm";

interface CanLoadOrderForm {
  type: typeof CAN_LOAD_ORDER_FORM;
  layout: SubLayoutType;
}

export type CustomLayoutsOrderFormEvents = GetLayoutMenuOpenState | IsTabVisible | ChartReady | CanLoadOrderForm;

export const SET_FULLSCREEN = "setFullScreen";

interface SetFullScreen extends CustomEvent {
  type: typeof SET_FULLSCREEN;
  isChartExpanded: boolean;
  layout?: SubLayoutType;
}

export const ORDER_FORM_VISIBILITY_CHANGE = "orderFormVisibilityChange";

interface OrderFormVisibilityChange extends CustomEvent {
  type: typeof ORDER_FORM_VISIBILITY_CHANGE;
  pair: string;
  visible: boolean;
}

export const TV_CONTAINER_RESIZE = "tvContainerResize";

interface TvContainerResize extends CustomEvent {
  type: typeof TV_CONTAINER_RESIZE;
  pair: string;
  rect: DOMRect;
}

export const HIDE_OPEN_ORDER_FORM = "hideOpenOrderForm";

interface HideOpenOrderForm {
  type: typeof HIDE_OPEN_ORDER_FORM;
  hide: boolean;
  pair?: string;
}

export const CREATE_ORDER_FORM = "createOrderForm";

interface CreateOrderForm extends CustomEvent {
  type: typeof CREATE_ORDER_FORM;
  layoutMarket: LayoutMarket;
  isDerivativesAcct: boolean;
}

export const DESTROY_ORDER_FORM = "destroyOrderForm";

interface DestroyOrderForm extends CustomEvent {
  type: typeof DESTROY_ORDER_FORM;
}

export type CustomLayoutsChartEvents =
  | SetFullScreen
  | OrderFormVisibilityChange
  | TvContainerResize
  | HideOpenOrderForm
  | CreateOrderForm
  | DestroyOrderForm;
