import { Fragment } from "react";
import { IconChevronLeftLarge } from "@hubble/icons";
import { optimizelyClient } from "@gemini-ui/analytics";
import GlobalAlert from "@gemini-ui/components/GlobalAlert";
import { maybeRenderBuildOverride } from "@gemini-ui/components/Header/utils";
import { OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";
import { usePageData } from "@gemini-ui/contexts";
import { Button, Flex, SectionMessage, Spacing, Text } from "@gemini-ui/design-system";
import OnboardingPageLayout from "@gemini-ui/pages/register/OnboardingLayout/OnboardingPageLayout";
import SimpleFooter from "@gemini-ui/pages/register/OnboardingLayout/SimpleFooter";
import {
  BackButtonContainer,
  GeminiLogoContainer,
  InnerContainer,
  MainContent,
  RegisterContainer,
  RegisterHeaderLogo,
} from "@gemini-ui/pages/register/OnboardingLayout/styles";
import { OnboardingProps } from "@gemini-ui/pages/register/OnboardingLayout/types";
import { SegmentType } from "@gemini-ui/pages/register/OnboardingLayout/utils";
import { useIntl } from "@gemini-ui/utils/intl";

const signOutRoute = jsRoutes.com.gemini.web.server.account.controllers.Application.signOut().url;

const OnboardingLayout = (props: OnboardingProps) => {
  const {
    title,
    customTitle,
    subTitle,
    children,
    rightHeader,
    showBackBtn,
    backBtnPropsOverride,
    showSecurityKeyError,
    ["data-testid"]: testID,
    activeOnboardingState,
  } = props;
  const { templateProps } = usePageData();
  const { intl } = useIntl();

  const plaidIDVCountries: { values: string[] } = optimizelyClient.getFeatureVariableJSON(
    OPTIMIZELY_FEATURE_FLAGS.WEB_PLAID_IDV_COUNTRIES,
    "countries"
  );
  const isPlaidIdvCountry = templateProps.user?.countryCode
    ? plaidIDVCountries?.values?.includes(templateProps.user?.countryCode?.toUpperCase())
    : false;
  const isVerticalNavigationEnabled = Boolean(activeOnboardingState);

  return (
    <Fragment>
      <GlobalAlert />
      <GeminiLogoContainer justify="space-between">
        <Flex alignItems="center" gap={Spacing.scale[2]}>
          <RegisterHeaderLogo />
          {maybeRenderBuildOverride("light")}
        </Flex>

        {rightHeader && (
          <Flex alignItems="center" gap={Spacing.scale[2]}>
            {rightHeader}
          </Flex>
        )}
      </GeminiLogoContainer>

      {showBackBtn && (
        <BackButtonContainer>
          <Button.Secondary
            display="inline-flex"
            size="sm"
            icon={<IconChevronLeftLarge />}
            aria-label={intl.formatMessage({ defaultMessage: "Back to login" })}
            href={signOutRoute}
            {...backBtnPropsOverride}
          />
        </BackButtonContainer>
      )}

      {isVerticalNavigationEnabled ? (
        <OnboardingPageLayout
          {...props}
          segmentType={isPlaidIdvCountry ? SegmentType.PLAID_IDV_STEPS : SegmentType.DEFAULT}
        />
      ) : (
        <Fragment>
          <RegisterContainer data-testid={testID}>
            <InnerContainer>
              <MainContent>
                <Flex.Column>
                  {customTitle || (
                    <Text.Heading size="lg" data-testid="title" mb={Boolean(subTitle) ? 1.5 : 1}>
                      {title}
                    </Text.Heading>
                  )}

                  {showSecurityKeyError && (
                    <SectionMessage
                      heading={intl.formatMessage({ defaultMessage: "Oops! There was an error" })}
                      mb={2}
                      statusType="alert"
                    >
                      {intl.formatMessage({
                        defaultMessage: "The security key prompt was either cancelled or timed out. Please try again.",
                      })}
                    </SectionMessage>
                  )}

                  {subTitle && (
                    <Text.Body size="sm" mb={3} mt={1} data-testid="subtitle">
                      {subTitle}
                    </Text.Body>
                  )}
                </Flex.Column>
                <Flex.Column align="left">{children}</Flex.Column>
              </MainContent>
              <SimpleFooter />
            </InnerContainer>
          </RegisterContainer>
        </Fragment>
      )}
    </Fragment>
  );
};

export default OnboardingLayout;
