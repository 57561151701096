import { Side } from "@gemini-ui/constants/orders";

type CustomEvent<T extends string = string> = {
  type: T;
  pair: string;
};

export const SET_LIMIT_ORDER_TYPE = "setLimitOrderType";

interface SetLimitOrderType extends CustomEvent {
  type: typeof SET_LIMIT_ORDER_TYPE;
}

export const SET_LIMIT_ORDER_SIDE = "setLimitOrderSide";

interface SetLimitOrderSide extends CustomEvent {
  type: typeof SET_LIMIT_ORDER_SIDE;
  value: Side;
}

export const GET_LIMIT_ORDER_QUANTITY = "getLimitOrderQuantity";

interface GetLimitOrderQuantity extends CustomEvent {
  type: typeof GET_LIMIT_ORDER_QUANTITY;
}

export const SET_LIMIT_ORDER_PRICE_INPUT = "setLimitOrderPriceInput";

interface SetLimitOrderPriceInput extends CustomEvent {
  type: typeof SET_LIMIT_ORDER_PRICE_INPUT;
  value: number;
}

export type TradingViewChartTicketEvents =
  | SetLimitOrderType
  | SetLimitOrderSide
  | GetLimitOrderQuantity
  | SetLimitOrderPriceInput;

export const SET_LIMIT_ORDER_QUANTITY = "setLimitOrderQuantity";

interface SetLimitOrderQuantity extends CustomEvent {
  type: typeof SET_LIMIT_ORDER_QUANTITY;
  value: string;
}

export const SET_LIMIT_ORDER_PRICE = "setLimitOrderPrice";

interface SetLimitOrderPrice extends CustomEvent {
  type: typeof SET_LIMIT_ORDER_PRICE;
  value: number;
}

export const UPDATE_LIMIT_ORDER_SIDE = "updateLimitOrderSide";

interface UpdateLimitOrderSide extends CustomEvent {
  type: typeof UPDATE_LIMIT_ORDER_SIDE;
  value: Side;
}

export const DESTROY_LIMIT_ORDER_PREVIEW = "destroyLimitOrderPreview";

interface DestroyLimitOrderPreview extends CustomEvent {
  type: typeof DESTROY_LIMIT_ORDER_PREVIEW;
}

export type TradingViewChartEvents =
  | SetLimitOrderQuantity
  | SetLimitOrderPrice
  | UpdateLimitOrderSide
  | DestroyLimitOrderPreview;
