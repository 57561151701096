import { IconNumber1Outlined, IconNumber2Outlined, IconNumber3Outlined, IconRewardFilled } from "@hubble/icons";
import { IntlShape } from "react-intl";
import { Button } from "@gemini-ui/design-system";

export const getReferralDetailsItems = (
  intl: IntlShape,
  handleShareLink: () => void,
  isFetchingReferralLink: boolean
) => [
  {
    icon: <IconNumber1Outlined />,
    title: intl.formatMessage({ defaultMessage: "Share your referral link" }),
    description: intl.formatMessage({ defaultMessage: "Via text, email or social media." }),
    button: (
      <Button.Primary size="sm" onClick={handleShareLink} loading={isFetchingReferralLink}>
        {intl.formatMessage({ defaultMessage: "Share link" })}
      </Button.Primary>
    ),
  },
  {
    icon: <IconNumber2Outlined />,
    title: intl.formatMessage({ defaultMessage: "They use your link to apply" }),
    description: intl.formatMessage({ defaultMessage: "And are approved for the Gemini Credit Card." }),
    button: null,
  },
  {
    icon: <IconNumber3Outlined />,
    title: intl.formatMessage({ defaultMessage: "You get $50" }),
    description: intl.formatMessage({ defaultMessage: "In the crypto reward chosen." }),
    button: null,
  },
  {
    icon: <IconRewardFilled />,
    title: intl.formatMessage({ defaultMessage: "$250 crypto bonus!" }),
    description: intl.formatMessage({ defaultMessage: "Get a bonus for every 5th referral." }),
    button: null,
  },
];

export const TOTAL_PROGRESS_TRACKER = 5;
export const REFERRAL_TERMS_LINK = "https://www.gemini.com/legal/credit-card-referral-program-terms";
