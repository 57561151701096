import { Dispatch } from "@reduxjs/toolkit";
import { Actions, BorderNode, DockLocation, Model, TabSetNode } from "flexlayout-react";
import Cookies from "js-cookie";
import { IntlShape } from "react-intl";
import { Params } from "react-router-dom";
import { optimizelyClient } from "@gemini-ui/analytics";
import { OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";
import { TradeActionTypes } from "@gemini-ui/pages/ActiveTrader/Spot/actions";
import {
  CUSTOM_LAYOUTS_CHART_EVENT,
  CUSTOM_LAYOUTS_ORDER_FORM_EVENT,
  ON_LAYOUT_EVENTS,
} from "@gemini-ui/pages/ActiveTrader/Spot/Charts/OrderBookChart/constants";
import { TradeState } from "@gemini-ui/pages/ActiveTrader/Spot/constants";
import { updateOrderFormUnDockedSettings } from "@gemini-ui/pages/ActiveTrader/Spot/dispatchActions";
import { emitter } from "@gemini-ui/pages/ActiveTrader/Spot/emitter";
import {
  isMenuTab,
  MenuTabs,
  PRIMARY_ORDER_FORM_ID,
  PRIMARY_TRADING_VIEW_ID,
  SECONDARY_ORDER_FORM_ID,
  SECONDARY_TRADING_VIEW_ID,
  SubLayoutType,
  TabSet,
} from "@gemini-ui/pages/ActiveTrader/Spot/TradeLayout/TradeFlexLayout/constants";
import {
  IS_TAB_VISIBLE,
  ORDER_FORM_VISIBILITY_CHANGE,
  TV_CONTAINER_RESIZE,
} from "@gemini-ui/pages/ActiveTrader/Spot/TradeLayout/TradeFlexLayout/CustomLayoutEvents/types";
import { pairLayoutTabs } from "@gemini-ui/pages/ActiveTrader/Spot/TradeLayout/TradeFlexLayout/model";
import {
  FlexLayoutEventListenerProps,
  FlexLayoutNodeEvent,
} from "@gemini-ui/pages/ActiveTrader/Spot/TradeLayout/TradeFlexLayout/types";
import { getOrderBookChartParent } from "@gemini-ui/pages/ActiveTrader/Spot/utils/tradingViewChartObjects/tradingViewChartEvents";

export const splitOrderbookAndMarketTrades = (intl: IntlShape, model: Model) => {
  const { EXCHANGE_ACTIVITY, ORDER_BOOK } = pairLayoutTabs(intl);

  model.doAction(Actions.deleteTab(EXCHANGE_ACTIVITY.id));
  const exchangeActivityNode = model.doAction(
    Actions.addNode(EXCHANGE_ACTIVITY, TabSet.MARKET_AND_ORDERBOOK, DockLocation.RIGHT, -1)
  );
  model.doAction(Actions.updateNodeAttributes(exchangeActivityNode.getParent().getId(), { id: TabSet.MARKET_TRADES }));

  model.doAction(Actions.deleteTab(ORDER_BOOK.id));
  const orderbookNode = model.doAction(Actions.addNode(ORDER_BOOK, TabSet.MARKET_TRADES, DockLocation.LEFT, -1));
  model.doAction(Actions.updateNodeAttributes(orderbookNode.getParent().getId(), { id: TabSet.ORDERBOOK }));
};

export const unifyOrderbookAndMarketTrades = (intl: IntlShape, model: Model) => {
  const { ORDER_BOOK } = pairLayoutTabs(intl);
  model.doAction(Actions.moveNode(ORDER_BOOK.id, TabSet.MARKET_TRADES, DockLocation.CENTER, 0));
  model.doAction(
    Actions.updateNodeAttributes(TabSet.MARKET_TRADES, { id: TabSet.MARKET_AND_ORDERBOOK, weight: 34, width: 360 })
  );
};

export const getActiveMenuTabId = (tabSetNode: TabSetNode | BorderNode) => {
  let activeId;
  tabSetNode.getChildren().forEach(tab => {
    const tabId = tab.getId();
    if (tab.isVisible()) {
      if (isMenuTab(tabId) && MenuTabs.indexOf(tabId) !== -1) {
        activeId = tabId;
      }
    }
  });
  return activeId;
};

const CUSTOM_LAYOUTS_OVERRIDE = "CUSTOM_LAYOUTS_OVERRIDE";

export const areCustomLayoutsEnabled = () => {
  const override = Cookies.get(CUSTOM_LAYOUTS_OVERRIDE);
  if (override === "enabled" || override === "disabled") {
    return override === "enabled" ? true : false;
  }

  return optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.WEB_LAYOUTS_CUSTOMIZATION_ENABLED);
};

export const getSublayoutVariantIds = (id: string) => [`${id}-primary`, `${id}-secondary`];

export const getNodeVisibility = (pair: string, tabId: string): Promise<boolean> => {
  return new Promise(resolve => {
    const listener = emitter.addListener(
      ON_LAYOUT_EVENTS.IS_TAB_VISIBLE_RESPONSE,
      ({ nodeIsVisible, validPair }: { validPair: string; nodeIsVisible: boolean }) => {
        if (pair === validPair) {
          resolve(nodeIsVisible);
          listener.remove();
        }
      }
    );

    emitter.emit(CUSTOM_LAYOUTS_ORDER_FORM_EVENT, { type: IS_TAB_VISIBLE, pair, tabId });
  });
};

export const toggleOrderFormParentContainer = (pair: string | undefined, show: boolean) => {
  if (!pair) return;

  const buySellComponent = document.querySelector(`[data-buy-sell-pair="${pair}"]`);

  if (buySellComponent && buySellComponent.closest(`.flexlayout__tab`)) {
    (buySellComponent.closest(`.flexlayout__tab`) as HTMLDivElement).style.display = show ? "" : "none";
  }
};

export const getOrderFormVisibleKey = (layout: string) => {
  return `${layout.toLowerCase()}OrderFormVisible` === "primaryOrderFormVisible"
    ? "primaryOrderFormVisible"
    : "secondaryOrderFormVisible";
};

export const switchOrderFormToFloating = ({
  layout,
  pair,
  dispatch,
  orderFormUnDockedSettings,
}: {
  layout: SubLayoutType;
  pair: string;
  dispatch: Dispatch<TradeActionTypes>;
  orderFormUnDockedSettings: TradeState["orderFormUnDockedSettings"];
}) => {
  const orderFormVisible = getOrderFormVisibleKey(layout);
  if (orderFormUnDockedSettings?.[orderFormVisible]) {
    dispatch(updateOrderFormUnDockedSettings(orderFormVisible, false));
  }
  emitter.emit(CUSTOM_LAYOUTS_CHART_EVENT, {
    type: ORDER_FORM_VISIBILITY_CHANGE,
    pair,
    visible: false,
  });
};

export const handleFlexLayoutEventListeners = ({ ids, model, type }: FlexLayoutEventListenerProps) => {
  for (let i = 0; i < ids.length; i++) {
    const { id, events } = ids[i];

    events.forEach(({ event, callback }) => {
      if (type === "add") {
        model.getNodeById(id)?.setEventListener(event, callback);
      } else {
        model.getNodeById(id)?.removeEventListener(event);
      }
    });
  }
};

export const getOrderFormFlexLayoutListeners = (
  params: Readonly<Params<string>>,
  orderFormUnDockedSettings: TradeState["orderFormUnDockedSettings"],
  dispatch: Dispatch<TradeActionTypes>
): FlexLayoutNodeEvent[] => {
  return [
    { id: PRIMARY_ORDER_FORM_ID, pair: params.pair },
    { id: SECONDARY_ORDER_FORM_ID, pair: params?.secondaryPair || "" },
  ].map(({ id, pair }) => ({
    id,
    events: [
      {
        event: `visibility`,
        callback: p => {
          // Note this will only capture when visibility changes to true
          emitter.emit(CUSTOM_LAYOUTS_CHART_EVENT, {
            type: ORDER_FORM_VISIBILITY_CHANGE,
            pair,
            visible: p.visible,
          });
        },
      },
      {
        event: `close`,
        callback: () => {
          switchOrderFormToFloating({
            layout: id.includes("primary") ? `primary` : `secondary`,
            pair,
            dispatch,
            orderFormUnDockedSettings,
          });
        },
      },
    ],
  }));
};

export const getTradingViewFlexLayoutListeners = (
  params: Readonly<Params<string>>,
  model: Model
): FlexLayoutNodeEvent[] => {
  return [
    { id: PRIMARY_TRADING_VIEW_ID, pair: params.pair },
    { id: SECONDARY_TRADING_VIEW_ID, pair: params?.secondaryPair || "" },
  ].map(({ id, pair }) => ({
    id,
    events: [
      {
        event: `resize`,
        callback: () => {
          // Hack: Using setTimeout to wait for the updated rect value
          setTimeout(() => {
            const orderBookChartParent = getOrderBookChartParent(pair);
            if (orderBookChartParent) {
              emitter.emit(CUSTOM_LAYOUTS_CHART_EVENT, {
                type: TV_CONTAINER_RESIZE,
                pair,
                rect: orderBookChartParent.getBoundingClientRect(),
              });
            }
          }, 0);
        },
      },
      {
        event: `close`,
        callback: () => {
          const orderForm = model.getNodeById(id.includes("primary") ? PRIMARY_ORDER_FORM_ID : SECONDARY_ORDER_FORM_ID);

          const showOrderFormParentContainer = !orderForm ? false : orderForm.isVisible() ? true : false;

          toggleOrderFormParentContainer(pair, showOrderFormParentContainer);

          emitter.emit(CUSTOM_LAYOUTS_CHART_EVENT, {
            type: ORDER_FORM_VISIBILITY_CHANGE,
            pair,
            visible: true,
          });
        },
      },
    ],
  }));
};
