import {
  UPDATE_CHART_NOTIFICATIONS,
  UPDATE_ORDER_FORM_UNDOCKED_SETTINGS,
  UpdateChartNotifications,
  UpdateOrderFormUnDockedSettings,
} from "@gemini-ui/pages/ActiveTrader/Spot/actions";
import { ChartNotifications, OrderFormUnDockedSettings } from "@gemini-ui/pages/ActiveTrader/Spot/constants";

export const updateOrderFormUnDockedSettings = (
  id: keyof OrderFormUnDockedSettings,
  value: UpdateOrderFormUnDockedSettings["payload"]["value"]
): UpdateOrderFormUnDockedSettings => ({
  type: UPDATE_ORDER_FORM_UNDOCKED_SETTINGS,
  payload: {
    id,
    value,
  },
});

export const updateChartNotificationSettings = (
  id: keyof ChartNotifications,
  value: UpdateChartNotifications["payload"]["value"]
): UpdateChartNotifications => ({
  type: UPDATE_CHART_NOTIFICATIONS,
  payload: {
    id,
    value,
  },
});
