import { Fragment, useCallback, useState } from "react";
import { useMedia } from "react-use";
import { EVENTS, track } from "@gemini-ui/analytics";
import { optimizelyClient } from "@gemini-ui/analytics/optimizely";
import { useFeatureFlags } from "@gemini-ui/components/FeatureFlag/FlagProvider";
import LazyLoadDerivativeDebitCardFundingModal from "@gemini-ui/components/Header/DerivativeDebitCardFunding/LazyLoadDerivativeDebitCardFundingModal";
import LazyLoadFeedbackModal from "@gemini-ui/components/Header/FeedbackModal/LazyLoadFeedbackModal";
import HeaderMenuLink from "@gemini-ui/components/Header/HeaderMenuLink";
import { LinkName } from "@gemini-ui/components/Header/HeaderMenuLink/constants";
import AccountSwitcherMenu from "@gemini-ui/components/Header/navigation/AccountSwitcherMenu";
import CardLink from "@gemini-ui/components/Header/navigation/CardLink/CardLink";
import GeminiHome from "@gemini-ui/components/Header/navigation/GeminiHome";
import IconContainer from "@gemini-ui/components/Header/navigation/IconContainer";
import MobileMenu from "@gemini-ui/components/Header/navigation/MobileMenu";
import { NotificationCenter } from "@gemini-ui/components/Header/navigation/NotificationCenter";
import {
  AUTO_OPEN_AT_PERP_MODAL_ON_LOAD,
  getGlobalModalState,
} from "@gemini-ui/components/Header/PerpOnboardingModal/constants";
import LazyLoadPerpsOnboardingModal from "@gemini-ui/components/Header/PerpOnboardingModal/LazyLoadPerpOnboardingModal";
import { HeaderContainer, HeaderLeftNav, HeaderRightNav } from "@gemini-ui/components/Header/styles";
import SubaccountRedirector from "@gemini-ui/components/Header/SubaccountRedirector";
import UkGeneralRiskWarning from "@gemini-ui/components/Header/UkGeneralRiskWarning";
import LazyLoadUnauthorizedDeviceModal from "@gemini-ui/components/Header/UnauthorizedDeviceDialog/LazyLoadUnauthorizedDeviceDialog";
import {
  isSwitcherEligiblePage,
  maybeRenderBuildOverride,
  navigateToSubaccount,
} from "@gemini-ui/components/Header/utils";
import LazyLoadVerificationPendingModal from "@gemini-ui/components/Header/VerificationPendingModal/LazyLoadVerificationPendingModal";
import { VerificationPendingActionType } from "@gemini-ui/components/Header/VerificationPendingModal/utils";
import { LazyModalWrapper, MODAL_TYPES } from "@gemini-ui/components/LazyLoading/LazyModalWrapper";
import LazyLoadCashDepositFlow from "@gemini-ui/components/Transfer/CashDepositFlow/LazyLoadCashDepositFlow";
import LazyLoadCashWithdrawFlow from "@gemini-ui/components/Transfer/CashWithdrawFlow/LazyLoadCashWithdrawFlow";
import LazyLoadCryptoDepositFlow from "@gemini-ui/components/Transfer/CryptoDepositFlow/LazyLoadCryptoDepositFlow";
import LazyLoadCryptoWithdrawFlow from "@gemini-ui/components/Transfer/CryptoWithdrawFlow/LazyLoadCryptoWithdrawFlow";
import { OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";
import { PageName } from "@gemini-ui/constants/initialData/pageName";
import { usePageData } from "@gemini-ui/contexts";
import { GlobalModalType, useGlobalModal, VerificationPendingProps } from "@gemini-ui/contexts/GlobalModal";
import { Button, Flex, HubbleProvider, mediaQuery } from "@gemini-ui/design-system";
import { areCustomLayoutsEnabled } from "@gemini-ui/pages/ActiveTrader/Spot/TradeLayout/TradeFlexLayout/utils";
import LazyLoadCreatePasskeyDialog from "@gemini-ui/pages/CreatePasskey/LazyLoadCreatePasskeyDialog";
import { useGrowFeatureFlags } from "@gemini-ui/pages/Earn/hooks";
import { PERPS_DISMISS_PROMO_CARD_STORAGE_KEY } from "@gemini-ui/pages/RetailTrade/Homepage/PerpetualsPromoCard/constants";
import { getIsGeminiUk } from "@gemini-ui/pages/transfers/utils";
import { getIsUserInOnboarding, useLockout } from "@gemini-ui/services/user/lockout";
import { FeatureElectionStatus } from "@gemini-ui/utils/constants";
import {
  getDerivativesLinkCopy,
  isDerivativesEnabled,
  LEADERBOARD_MODAL_STORAGE_KEY,
  perpsLinkCTA,
  shouldDisplayTheLeaderboardModal,
  shouldShowDerivativesLink,
  useCheckPerpEligibility,
} from "@gemini-ui/utils/derivativeAccountUtils";
import { useIntl } from "@gemini-ui/utils/intl";
import { useLeaderboardOptInService } from "@gemini-ui/utils/leaderboard/useLeaderboardOptInService";
import storage from "@gemini-ui/utils/storage";

const LazyVerificationPendingFromBasicPlusModal = LazyModalWrapper(
  () => import("./VerificationPendingFromBasicPlusModal")
);

const LazyUpgradeFromBasicPlusModal = LazyModalWrapper(() => import("./UpgradeFromBasicPlusModal"));

interface Props {
  fancyBorder?: boolean;
}

const Header = ({ fancyBorder = false }: Props) => {
  const { intl } = useIntl();

  const {
    pageName,
    templateProps: {
      user,
      user: {
        advancedTradeUIEnabled = false,
        countryCode,
        fullName,
        gcTradingEnabled = false,
        groupsInfo,
        institutionName,
        isCustodyAccount = false,
        isInstitutional = false,
        subaccountHashid,
        subaccounts,
        eotcEnabled = false,
      },
      account: { geminiEntity },
    },
  } = usePageData();
  const {
    AdvancedMarketsPageEnabled,
    SettlementEnabled,
    TradePerpEnabled,
    SettlementInstitutionalOnly,
    PerpsUiEnabled,
    LeaderboardOptInEnabled,
  } = useFeatureFlags();

  const { eligibleForGrow } = useGrowFeatureFlags();

  const { isModalOpen, toggleModal, setModalState, modalState, modalProps } = useGlobalModal();
  const { lockout } = useLockout();

  const [mobileMenuActive, setMobileMenuActive] = useState(false);

  const customLayoutsEnabled = areCustomLayoutsEnabled();
  const isDerivativesAccount = isDerivativesEnabled(user);
  const tabletSmDown = useMedia(mediaQuery.tabletSmDown);
  const { optInLeaderboardStatus } = useLeaderboardOptInService();
  const isOptInLeaderboard = Boolean(optInLeaderboardStatus) && optInLeaderboardStatus === FeatureElectionStatus.OptIn;
  const params = new URL(document.location.href).searchParams;
  const forceLeaderboardModal = Boolean(params.get("leaderboardOptIn"));
  const isPerpsEligible = useCheckPerpEligibility({
    user,
    TradePerpEnabled,
    PerpsUiEnabled,
    callback: useCallback(() => {
      try {
        const storageKey = AUTO_OPEN_AT_PERP_MODAL_ON_LOAD;
        if (pageName === PageName.Trade) {
          const leaderboardModalStorageKey = LEADERBOARD_MODAL_STORAGE_KEY;
          const forcedModalState = storage.get(storageKey) as GlobalModalType;
          if (Boolean(forcedModalState) && forcedModalState in GlobalModalType) {
            toggleModal(forcedModalState);
          } else {
            const leaderboardOptInSession = storage.get(leaderboardModalStorageKey, "sessionStorage");
            if (
              shouldDisplayTheLeaderboardModal(
                forceLeaderboardModal,
                optInLeaderboardStatus,
                leaderboardOptInSession,
                LeaderboardOptInEnabled,
                isInstitutional
              )
            ) {
              if (!forceLeaderboardModal) {
                storage.set(leaderboardModalStorageKey, true, "sessionStorage");
              }
              track(EVENTS.LEADERBOARD_MODAL_CONDENSED_AUTO_DISPLAY.name);
              toggleModal(GlobalModalType.PerpsOnboardingLeaderboardInfoModal);
            }
          }
        }
        storage.remove(storageKey);
      } catch (e) {}
    }, [
      pageName,
      toggleModal,
      optInLeaderboardStatus,
      LeaderboardOptInEnabled,
      forceLeaderboardModal,
      isInstitutional,
    ]),
  });

  const isConsolidatedPortfolioEnabled = optimizelyClient.isFeatureEnabled(
    OPTIMIZELY_FEATURE_FLAGS.WEB_PORTFOLIO_CONSOLIDATION
  );

  const maybeRenderAccountSwitcher = (pageName: PageName) => {
    const needsActivateDerivativeAccount = isPerpsEligible && !subaccounts.some(({ derivatives }) => derivatives);
    const switcherEligiblePage = isSwitcherEligiblePage(pageName);
    return (
      subaccounts &&
      subaccountHashid &&
      switcherEligiblePage &&
      (subaccounts.length !== 1 || needsActivateDerivativeAccount) && (
        <AccountSwitcherMenu
          needsActivateAccount={needsActivateDerivativeAccount}
          fancyBorder={fancyBorder}
          enableDropdown={subaccounts.length > 1 || needsActivateDerivativeAccount}
          data-testid="subaccount-switcher"
          mobileMenuActive={mobileMenuActive}
        />
      )
    );
  };

  const moreThanOneAccount = subaccounts && subaccounts.length > 1;
  const showClearingLink = gcTradingEnabled && advancedTradeUIEnabled && !isCustodyAccount;
  const showCardLink = countryCode === "us" && !isInstitutional;
  const isUK = countryCode === "gb" || getIsGeminiUk(geminiEntity);
  const isRewardsReferralActive = optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.WEB_REWARDS_REFERRALS_V2);
  const showRewardsCTA = isRewardsReferralActive && !user.isCustodyAccount && !user.isInstitutional;
  const isOnboardingInProgress = getIsUserInOnboarding(lockout);

  const StakeLink = () => {
    if (!eligibleForGrow) return null;

    return (
      <HeaderMenuLink
        linkName={LinkName.STAKE}
        name={intl.formatMessage({ defaultMessage: "Stake" })}
        href={isOnboardingInProgress ? "#" : "/stake"}
        onClick={() => {
          if (isOnboardingInProgress) {
            toggleModal<VerificationPendingProps>(GlobalModalType.VerificationPending, {
              actionType: VerificationPendingActionType.Stake,
            });
          }
        }}
        active={pageName === PageName.Stake}
        hideMobile
      />
    );
  };

  const AdvancedMarkets = () => {
    if (!AdvancedMarketsPageEnabled) return null;
    return (
      <HeaderMenuLink
        linkName={LinkName.ADVANCED_MARKETS}
        name={intl.formatMessage({ defaultMessage: "Markets" })}
        href="/advanced/markets"
        active={pageName === PageName.AdvancedMarkets}
        hideMobile
      />
    );
  };

  const Settlement = () => {
    if (!SettlementEnabled || (!isInstitutional && SettlementInstitutionalOnly)) return null;
    return (
      <HeaderMenuLink
        linkName={LinkName.SETTLEMENT}
        name={intl.formatMessage({ defaultMessage: "Settlement" })}
        active={pageName === PageName.Settlement}
        href={jsRoutes.com.gemini.web.server.trading.controllers.SettlementController.get().url}
      />
    );
  };

  const EOTC = () => {
    if (!eotcEnabled) return null;
    return (
      <HeaderMenuLink
        linkName={LinkName.EOTC}
        name={intl.formatMessage({ defaultMessage: "eOTC" })}
        href="/eotc"
        active={pageName === PageName.EOTC}
        hideMobile
      />
    );
  };
  //TODO remove spaghetti code when LeaderboardOptInEnabled is true and stable
  const PerpetualsOnboardingLink = () => {
    if (
      !shouldShowDerivativesLink(
        LeaderboardOptInEnabled,
        isDerivativesAccount,
        isOptInLeaderboard,
        storage.get(PERPS_DISMISS_PROMO_CARD_STORAGE_KEY) === "true",
        isPerpsEligible
      )
    )
      return null;
    const derivativesAccount = subaccounts.find(account => account.derivatives);
    const derivativesCopy = getDerivativesLinkCopy(
      isInstitutional,
      LeaderboardOptInEnabled,
      isOptInLeaderboard,
      Boolean(subaccounts.find(account => account.derivatives)),
      customLayoutsEnabled,
      intl
    );
    const { title: linkCopy, icon: IconCopy } = derivativesCopy;
    const iconProps = Boolean(derivativesCopy?.label)
      ? { ["aria-label"]: derivativesCopy?.label, icon: IconCopy }
      : { leftElement: IconCopy };
    return (
      <HubbleProvider scheme="dark">
        <Flex height="100%" align="center" mr={0.5}>
          <Button.Tertiary
            size="sm"
            data-testid="perps-onboarding-menu"
            onClick={() => {
              perpsLinkCTA(
                Boolean(derivativesAccount),
                isOptInLeaderboard,
                user.isInstitutional,
                LeaderboardOptInEnabled,
                isPerpsEligible,
                toggleModal,
                () => navigateToSubaccount({ hashid: derivativesAccount.hashid, subaccounts, pageName })
              );
            }}
            {...iconProps}
          >
            {linkCopy}
          </Button.Tertiary>
        </Flex>
      </HubbleProvider>
    );
  };

  const PortfolioLink = () => (
    <HeaderMenuLink
      linkName={LinkName.PORTFOLIO}
      name={intl.formatMessage({ defaultMessage: "Portfolio" })}
      href="/portfolio"
      active={pageName === PageName.Portfolio}
      hideMobile
    />
  );

  const isMarketSubpage = (pathname: string) => {
    const page = pathname.split("/")?.[1] ?? "";
    switch (page) {
      case "buy":
      case "sell":
      case "convert":
        return true;
      default:
        return false;
    }
  };

  const retailMenuLinks = (
    <Fragment>
      <HeaderMenuLink
        linkName={LinkName.HOME}
        name={intl.formatMessage({ defaultMessage: "Home" })}
        href="/home"
        active={pageName === PageName.Home || window?.location?.pathname === "/"}
        hideMobile
      />

      <HeaderMenuLink
        linkName={LinkName.MARKET}
        name={intl.formatMessage({ defaultMessage: "Trade" })}
        active={
          pageName === PageName.Market ||
          window?.location?.pathname === "/market" ||
          isMarketSubpage(window?.location?.pathname)
        }
        href="/market"
        hideMobile
      />
      <StakeLink />
      {showCardLink && <CardLink pageName={pageName} />}
    </Fragment>
  );

  const activeTraderLinks = (
    <Fragment>
      <HeaderMenuLink
        linkName={LinkName.TRADE}
        name={intl.formatMessage({ defaultMessage: "Trade" })}
        href="/trade"
        active={pageName === PageName.Trade}
        hideMobile
      />
      <AdvancedMarkets />
      <StakeLink />
      {showCardLink && <CardLink pageName={pageName} />}
    </Fragment>
  );

  const headerMenuLinks = (
    <Fragment>
      {isCustodyAccount && (
        <HeaderMenuLink
          linkName={LinkName.DASHBOARD}
          href="/custody"
          name={intl.formatMessage({ defaultMessage: "Dashboard" })}
          active={pageName === PageName.CustodyDashboard}
          hideMobile
        />
      )}

      {!isCustodyAccount && (advancedTradeUIEnabled ? activeTraderLinks : retailMenuLinks)}

      {(isConsolidatedPortfolioEnabled || (!advancedTradeUIEnabled && !isCustodyAccount)) && <PortfolioLink />}

      {showClearingLink && (
        <HeaderMenuLink
          linkName={LinkName.CLEARING}
          name={intl.formatMessage({ defaultMessage: "Clearing" })}
          href={jsRoutes.com.gemini.web.server.trading.controllers.GCOrderController.get().url}
          active={pageName === PageName.ClearingDashboard}
          hideMobile
        />
      )}

      {!isCustodyAccount && advancedTradeUIEnabled && !isConsolidatedPortfolioEnabled && (
        <HeaderMenuLink
          linkName={LinkName.BALANCES}
          name={intl.formatMessage({ defaultMessage: "Balances" })}
          active={pageName === PageName.Balances || pageName === PageName.BalancesV2}
          href={jsRoutes.com.gemini.web.server.trading.controllers.BalanceControllerV2.get().url}
        />
      )}

      <Settlement />
      <EOTC />
    </Fragment>
  );

  const largeScreenLayout = (
    <HubbleProvider scheme="dark">
      <HeaderLeftNav data-testid="HeaderLeftNav">
        <GeminiHome moreThanOneAccount={moreThanOneAccount} />
        {maybeRenderAccountSwitcher(pageName)}
        {isSwitcherEligiblePage(pageName) && headerMenuLinks}
        {showRewardsCTA && (
          <HeaderMenuLink
            linkName={LinkName.Rewards}
            name={intl.formatMessage({ defaultMessage: "Rewards" })}
            onClick={() => {
              track(EVENTS.REWARD_ENTRY_POINT.name, {
                [EVENTS.REWARD_ENTRY_POINT.properties.INITIATED_FROM]: "Web Global Nav",
              });
            }}
            active={pageName === PageName.Rewards}
            href={jsRoutes.com.gemini.web.server.onboarding.controllers.services.RewardsController.getRewardsPage().url}
          />
        )}
        {/* istanbul ignore next */}
        {maybeRenderBuildOverride("dark")}
      </HeaderLeftNav>

      <HeaderRightNav data-testid="HeaderRightNav">
        <PerpetualsOnboardingLink />
        <IconContainer
          moreThanOneAccount={moreThanOneAccount}
          isDerivativesAccount={isDerivativesAccount}
          lockout={lockout}
        />
      </HeaderRightNav>
    </HubbleProvider>
  );

  const smallScreenLayout = (
    <HubbleProvider scheme="dark">
      <HeaderLeftNav data-testid="HeaderLeftNav">
        <GeminiHome moreThanOneAccount={moreThanOneAccount} mobileMenuActive={mobileMenuActive} />
        {maybeRenderAccountSwitcher(pageName)}
      </HeaderLeftNav>
      <HeaderRightNav data-testid="HeaderRightNav">
        {customLayoutsEnabled && <div id="active-trader-layout-button"></div>}
        <NotificationCenter mobileMenuActive={mobileMenuActive} />
        <MobileMenu
          userName={fullName}
          institutionName={institutionName}
          advancedTradeUIEnabled={advancedTradeUIEnabled}
          showClearingLink={showClearingLink}
          isCustodyAccount={isCustodyAccount}
          moreThanOneAccount={moreThanOneAccount}
          groupsInfo={groupsInfo}
          countryCode={countryCode}
          isInstitutional={isInstitutional}
          setMobileMenuActive={setMobileMenuActive}
        />
      </HeaderRightNav>
    </HubbleProvider>
  );

  const isDepositCashModalOpen = isModalOpen(GlobalModalType.CashDepositModal);
  const isPermanentFeedbackModalOpen = isModalOpen(GlobalModalType.PermanentFeedbackModal);
  const isVerificationPendingModalOpen = isModalOpen(GlobalModalType.VerificationPending);
  const isCryptoDepositModalOpen = isModalOpen(GlobalModalType.CryptoDepositModal);
  const isCryptoWithdrawModalOpen = isModalOpen(GlobalModalType.WithdrawCryptoModal);
  const isPerpsOnboardingModalOpen =
    [
      GlobalModalType.PerpsOnboardingIntroductionModal,
      GlobalModalType.PerpsOnboardingAccountActivateFormModal,
      GlobalModalType.PerpsOnboardingAccountUpdateFormModal,
      GlobalModalType.PerpsOnboardingLeaderboardInfoModal,
      GlobalModalType.PerpsIneligibleModal,
      GlobalModalType.PerpsFundingInstructions,
      GlobalModalType.PerpsFundingInstructionsSettings,
      GlobalModalType.PerpsFundAccount,
    ].some(isModalOpen) && isPerpsEligible;
  const isTransferModalOpen = [GlobalModalType.PerpsOnboardingTransferFormModal].some(isModalOpen);
  const isDerivativeDebitCardFundingModalOpen = [GlobalModalType.DerivativeDebitCardFundingModal].some(isModalOpen);
  const initialPerpsOnboardingModalState = getGlobalModalState(modalState);
  const isATIFeedbackModalOpen = isModalOpen(GlobalModalType.ATIFeedbackModal);
  const isCashWithdrawalModalOpen = isModalOpen(GlobalModalType.CashWithdrawModal);
  const isCreatePasskeyModalOpen = isModalOpen(GlobalModalType.CreatePasskey);
  const isUnauthorizedDeviceModalOpen = isModalOpen(GlobalModalType.UnauthorizedDeviceDialog);
  const isVerificationFromBasicPlusModalOpen = isModalOpen(GlobalModalType.VerificationPendingFromBasicPlusModal);
  const isUpgradeFromBasicPlusModalOpen = isModalOpen(GlobalModalType.UpgradeFromBasicPlusModal);

  let content = (
    <HeaderContainer data-testid="Header">
      {pageName !== PageName.Portfolio && <SubaccountRedirector />}
      {!tabletSmDown ? largeScreenLayout : smallScreenLayout}
      {isDepositCashModalOpen && (
        <LazyLoadCashDepositFlow
          isOpen={isDepositCashModalOpen}
          onClose={() => {
            toggleModal(GlobalModalType.CashDepositModal, { currency: undefined });
          }}
          defaultCurrency={modalProps[GlobalModalType.CashDepositModal]?.currency}
          subaccountHashid={modalProps[GlobalModalType.CashDepositModal]?.subaccountHashid || subaccountHashid}
        />
      )}
      {isVerificationPendingModalOpen && (
        <LazyLoadVerificationPendingModal
          onClose={() => {
            toggleModal(GlobalModalType.VerificationPending);
          }}
          lockout={lockout}
          actionType={modalProps[GlobalModalType.VerificationPending].actionType}
        />
      )}
      {isCashWithdrawalModalOpen && (
        <LazyLoadCashWithdrawFlow
          isOpen={isCashWithdrawalModalOpen}
          onClose={() => {
            toggleModal(GlobalModalType.CashWithdrawModal);
          }}
          defaultCurrency={modalProps[GlobalModalType.CashWithdrawModal]?.currency}
        />
      )}
      {(isPerpsOnboardingModalOpen || isTransferModalOpen) && (
        <LazyLoadPerpsOnboardingModal
          isOpen={isPerpsOnboardingModalOpen || isTransferModalOpen}
          onClose={() => setModalState(GlobalModalType.None)}
          initialModalState={initialPerpsOnboardingModalState}
        />
      )}
      {isDerivativeDebitCardFundingModalOpen && (
        <LazyLoadDerivativeDebitCardFundingModal
          isOpen={isDerivativeDebitCardFundingModalOpen}
          onClose={() => setModalState(GlobalModalType.None)}
        />
      )}
      {isATIFeedbackModalOpen && (
        <LazyLoadFeedbackModal
          isOpen={isATIFeedbackModalOpen}
          onClose={() => toggleModal(GlobalModalType.ATIFeedbackModal)}
          modalType={GlobalModalType.ATIFeedbackModal}
        />
      )}
      {isPermanentFeedbackModalOpen && (
        <LazyLoadFeedbackModal
          isOpen={isPermanentFeedbackModalOpen}
          onClose={() => toggleModal(GlobalModalType.PermanentFeedbackModal)}
          modalType={GlobalModalType.PermanentFeedbackModal}
        />
      )}
      {isCryptoDepositModalOpen && (
        <LazyLoadCryptoDepositFlow
          isOpen={isModalOpen(GlobalModalType.CryptoDepositModal)}
          onClose={() => {
            toggleModal(GlobalModalType.CryptoDepositModal);
          }}
          modalType={MODAL_TYPES.Dialog}
          preSelectedCrypto={modalProps[GlobalModalType.CryptoDepositModal]?.preSelectedCrypto}
          entrypoint={modalProps[GlobalModalType.CryptoDepositModal]?.entrypoint}
        />
      )}
      {isCryptoWithdrawModalOpen && (
        <LazyLoadCryptoWithdrawFlow
          isOpen={isModalOpen(GlobalModalType.WithdrawCryptoModal)}
          onClose={() => toggleModal(GlobalModalType.WithdrawCryptoModal)}
          modalType={MODAL_TYPES.Dialog}
        />
      )}
      {isCreatePasskeyModalOpen && (
        <LazyLoadCreatePasskeyDialog
          type="standard"
          onClose={() => toggleModal(GlobalModalType.CreatePasskey)}
          onPasskeyDone={() => toggleModal(GlobalModalType.CreatePasskey)}
        />
      )}
      {isUnauthorizedDeviceModalOpen && (
        <LazyLoadUnauthorizedDeviceModal
          isOpen={isUnauthorizedDeviceModalOpen}
          onClose={() => toggleModal(GlobalModalType.None)}
          modalType={MODAL_TYPES.Dialog}
        />
      )}
      {isUpgradeFromBasicPlusModalOpen && (
        <LazyUpgradeFromBasicPlusModal
          onClose={() => {
            toggleModal(GlobalModalType.UpgradeFromBasicPlusModal);
          }}
        />
      )}
      {isVerificationFromBasicPlusModalOpen && (
        <LazyVerificationPendingFromBasicPlusModal
          onClose={() => {
            toggleModal(GlobalModalType.VerificationPendingFromBasicPlusModal);
          }}
        />
      )}
    </HeaderContainer>
  );

  if (isUK) {
    content = (
      <Flex flexDirection="column">
        <UkGeneralRiskWarning />
        {content}
      </Flex>
    );
  }

  return content;
};

export default Header;
