import styled from "@emotion/styled";
import { Button, Flex, HubbleButton, Spacing } from "@gemini-ui/design-system";
import { border, Colors } from "@gemini-ui/design-system/primitives";

const ALERT_TYPE_BG_COLORS = {
  info: Colors.blue[800],
};

export const ToolbarContainer = styled.div<{ isChartExpanded: boolean }>`
  font-size: 11px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${Spacing.scale[1]};
  margin-top: ${({ isChartExpanded }) => (isChartExpanded ? Spacing.scale[1] : 0)};
`;

export const FlexToolbar = styled(Flex)`
  border-left: 1px solid ${p => p.theme.colorScheme.card.border.filled};
`;

export const ZoomButton = styled(HubbleButton.Tertiary)`
  border-radius: ${border.radius.sm};

  &:hover {
    background-color: ${p => p.theme.colorScheme.elevated.background.modal};

    &:active,
    &:focus {
      background-color: ${p => p.theme.colorScheme.elevated.background.modal};
    }

    svg {
      fill: ${p => p.theme.colorScheme.content.primary};
    }
  }

  &:active,
  &:focus {
    background-color: transparent;
  }
`;

export const ChartConfigToolbar = styled.div`
  padding: 0 ${Spacing.scale["0.5"]};
  display: flex;
`;

export const ToolbarMenuHeader = styled.div<{ isActive?: boolean }>`
  display: flex;
  height: 24px;
  align-items: center;
  background: ${({ isActive }) => (isActive ? Colors.gray[900] : "transparent")};
  color: ${({ isActive }) => (isActive ? Colors.white : Colors.gray[400])};
  border-radius: 5px;
  &:hover,
  &:focus {
    color: ${Colors.white};
    background: ${Colors.gray[900]};

    svg {
      path {
        fill: ${Colors.white};
      }
    }
  }
`;

export const ToolbarMenuContainer = styled.div<{ br?: boolean; bl?: boolean }>`
  display: flex;
  align-items: center;
  font-size: 12px;
  color: ${Colors.gray[500]};
  flex-shrink: 0;
`;

export const CaretIconContainer = styled.div<{ isActive?: boolean }>`
  width: ${Spacing.scale[1]};
  margin-left: ${Spacing.scale[1]};
  transition: transform 100ms ease-in-out;
  line-height: 0;

  svg {
    width: 100%;

    path {
      fill: ${({ isActive }) => (isActive ? Colors.white : Colors.gray[400])};
    }
  }

  transform: ${({ isActive }) => (isActive ? "none" : "rotate(180deg)")};
`;

export const TradingViewChart = styled.div`
  padding-top: ${Spacing.scale[0.5]};
  border-radius: ${border.radius.panel.tab};

  iframe {
    border-radius: ${border.radius.panel.tab};
  }
`;

export const MenuToggle = styled(Button)`
  font-size: 12px;
  font-weight: 600;
  border-radius: 0;
  background: transparent;
  padding: ${Spacing.scale[0.5]} ${Spacing.scale[1]};
  height: unset;
  min-width: 20px;
`;

export const ChartLevelAlertContainer = styled.div<{ statusType: "alert" | "info" }>`
  margin-bottom: ${Spacing.scale[1]};

  & > div {
    background-color: ${p => ALERT_TYPE_BG_COLORS[p.statusType]};
  }
`;

export const Resizer = styled.div<{ showSplitChart: boolean }>`
  background-color: ${Colors.black};
  user-select: none;
  ${({ showSplitChart }) => !showSplitChart && "display: none;"}

  &[data-direction="horizontal"] {
    border: 1px solid ${Colors.gray[800]};
    border-top: none;

    cursor: ew-resize;
    height: 100%;
    width: 5px;
  }
  &[data-direction="vertical"] {
    border-bottom: 1px solid ${Colors.gray[800]};
    cursor: ns-resize;
    height: 4px;
    width: 100%;
  }
`;

export const ToolbarSeparator = styled.div`
  padding-left: ${Spacing.scale[1]};
`;

export const CHART_BACKGROUND_COLOR = "#151718";

export const customCSS = `
  [data-dialog-name] {
    background: ${Colors.gray[900]};
  }

  .theme-dark:root {
    --tv-color-platform-background: ${Colors.gray[900]};
    --tv-color-pane-background: ${CHART_BACKGROUND_COLOR}; 
    --tv-color-toolbar-button-background-hover: ${Colors.gray[900]};
    --tv-color-toolbar-button-background-expanded: ${Colors.gray[900]};
    --tv-color-toolbar-button-background-active: ${Colors.gray[800]};
    --tv-color-toolbar-button-background-active-hover: ${Colors.gray[800]};
    --tv-color-toolbar-button-text: ${Colors.gray[400]};
    --tv-color-toolbar-button-text-hover: ${Colors.white};
    --tv-color-toolbar-button-text-active: ${Colors.white};
    --tv-color-toolbar-button-text-active-hover: ${Colors.white};
    --tv-color-item-active-text: ${Colors.white};
    --tv-color-toolbar-toggle-button-background-active: ${Colors.gray[800]};
    --tv-color-toolbar-toggle-button-background-active-hover: ${Colors.gray[800]};
    --tv-color-toolbar-divider-background: ${Colors.gray[800]};
    --tv-color-toolbar-save-layout-loader: ${Colors.gray[400]};

    --tv-color-popup-background: ${Colors.gray[800]};
    --tv-color-popup-element-text: ${Colors.white};
    --tv-color-popup-element-text-hover: ${Colors.white};
    --tv-color-popup-element-background-hover: ${Colors.gray[700]};
    --tv-color-popup-element-divider-background:  ${Colors.gray[700]};
    --tv-color-popup-element-secondary-text: ${Colors.gray[400]};
    --tv-color-popup-element-hint-text: ${Colors.gray[400]};
    --tv-color-popup-element-text-active: ${Colors.white};
    --tv-color-popup-element-background-active: ${Colors.gray[700]};
    --tv-color-popup-element-toolbox-text: ${Colors.gray[400]};
    --tv-color-popup-element-toolbox-text-hover: ${Colors.white};
    --tv-color-popup-element-toolbox-text-active-hover: ${Colors.white};
    --tv-color-popup-element-toolbox-background-hover: ${Colors.gray[900]};
    --tv-color-popup-element-toolbox-background-active-hover: ${Colors.gray[900]};
  }
`;
