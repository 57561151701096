import { MutableRefObject, useRef } from "react";
import { defineMessage, IntlShape } from "react-intl";
import { useEffectOnce } from "react-use";
import { CurrencyPairDetail, getDecimalsForPrice } from "@gemini-common/scripts/constants/currencies";
import { initMoneyFormat } from "@gemini-common/scripts/Money/utils";
import { EVENTS, optimizelyClient, track } from "@gemini-ui/analytics";
import { HistoricalPrices } from "@gemini-ui/constants";
import { OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";
import { API_ORDER_TYPES, MarketOrder, Order, ORDER_SIDES, ORDER_TYPES } from "@gemini-ui/constants/orders";
import { Colors } from "@gemini-ui/design-system";
import { TicketParentProps } from "@gemini-ui/pages/ActiveTrader/Spot/BuySell/Ticket/constants";
import { Chart } from "@gemini-ui/pages/ActiveTrader/Spot/Charts/OrderBookChart";
import {
  TV_LIMIT_ORDER_PREVIEW_CHART,
  TV_LIMIT_ORDER_PREVIEW_TICKET,
} from "@gemini-ui/pages/ActiveTrader/Spot/Charts/OrderBookChart/constants";
import { CHART_BACKGROUND_COLOR } from "@gemini-ui/pages/ActiveTrader/Spot/Charts/OrderBookChart/styles";
import { TradeState } from "@gemini-ui/pages/ActiveTrader/Spot/constants";
import { emitter, EmitterSubscription } from "@gemini-ui/pages/ActiveTrader/Spot/emitter";
import {
  DESTROY_LIMIT_ORDER_PREVIEW,
  GET_LIMIT_ORDER_QUANTITY,
  SET_LIMIT_ORDER_PRICE,
  SET_LIMIT_ORDER_PRICE_INPUT,
  SET_LIMIT_ORDER_QUANTITY,
  SET_LIMIT_ORDER_SIDE,
  SET_LIMIT_ORDER_TYPE,
  TradingViewChartEvents,
  TradingViewChartTicketEvents,
  UPDATE_LIMIT_ORDER_SIDE,
} from "@gemini-ui/pages/ActiveTrader/Spot/utils/tradingViewChartObjects/types";
import { InputNumberT } from "@gemini-ui/utils/inputNumber";
import {
  ActionId,
  ActionsFactory,
  CreateContextMenuParams,
  IActionVariant,
  IChartWidgetApi,
  IOrderLineAdapter,
} from "@gemini-web/src/tradingViewCharting/charting_library";

const {
  ACTIVE_TRADER: {
    TRADING_VIEW_CHART_ORDERS: {
      SET_LIMIT_PREVIEW,
      SET_LIMIT_QUANTITY,
      SET_LIMIT_PRICE,
      SET_LIMIT_SIDE,
      REMOVE_LIMIT_PREVIEW,
    },
  },
} = EVENTS;

const buyColor = Colors.green["600"];
const sellColor = Colors.red["600"];
const textColor = Colors.gray["100"];

export const createOrderLine = (
  activeChart: IChartWidgetApi,
  intl: IntlShape,
  activeOrderLines: { id: string; remove: () => void }[],
  openOrders: Order[],
  onCancelOrder: (order: Order) => void
) => {
  openOrders.forEach(openOrder => {
    const color = openOrder.side === ORDER_SIDES.BUY ? buyColor : sellColor;
    const side =
      openOrder.side === ORDER_SIDES.BUY
        ? intl.formatMessage({ defaultMessage: "Buy" })
        : intl.formatMessage({ defaultMessage: "Sell" });
    const type = openOrder?.stopPrice
      ? intl.formatMessage({ defaultMessage: "Stop-Limit" })
      : intl.formatMessage({ defaultMessage: "Limit" });

    const orderLine = activeChart
      .createOrderLine({ disableUndo: true })
      .setText(`${side} ${type}`)
      .setQuantity(openOrder.remainingQuantity.value.toString())
      .setPrice(Number(openOrder.price.value))
      .onCancel(openOrder.hashid, function () {
        onCancelOrder(openOrder);
      })
      .setCancelTooltip(intl.formatMessage({ defaultMessage: "Cancel order" }))
      .setLineStyle(2)
      .setLineColor(color)
      .setBodyBorderColor(color)
      .setQuantityBackgroundColor(color)
      .setQuantityBorderColor(color)
      .setCancelButtonBorderColor(color)
      .setBodyBackgroundColor(CHART_BACKGROUND_COLOR)
      .setCancelButtonBackgroundColor(CHART_BACKGROUND_COLOR)
      .setBodyTextColor(textColor)
      .setCancelButtonIconColor(textColor);

    if (!activeOrderLines.find(o => o.id === openOrder.hashid)) {
      activeOrderLines.push({
        id: openOrder.hashid,
        remove: () => {
          orderLine.remove();
        },
      });
    }
  });
};

export const showHistoricalTrades = (
  activeChart: IChartWidgetApi,
  tradingPair: CurrencyPairDetail,
  trades: Order[],
  intl: IntlShape,
  historicalTradeShapes: { id: string; remove: () => void }[]
) => {
  const priceFormat = initMoneyFormat({
    currency: tradingPair.priceCurrency || "USD",
    locale: intl.locale,
    decimals: tradingPair.symbol ? getDecimalsForPrice(tradingPair.symbol) : 0,
  });

  trades.map(trade => {
    const orderType = trade.type !== API_ORDER_TYPES.ORDER ? `market` : trade?.stopPrice ? `stop-limit` : `limit`;
    const orderTypeText =
      orderType === "market"
        ? intl.formatMessage({
            defaultMessage: "Market",
          })
        : orderType === "stop-limit"
        ? intl.formatMessage({
            defaultMessage: "Stop-Limit",
          })
        : intl.formatMessage({
            defaultMessage: "Limit",
          });
    const fillPrice = trade.avgPrice.value;
    const side =
      trade.side === ORDER_SIDES.BUY
        ? intl.formatMessage({
            defaultMessage: "Buy",
          })
        : intl.formatMessage({
            defaultMessage: "Sell",
          });
    const color = trade.side === ORDER_SIDES.BUY ? buyColor : sellColor;

    const qty = orderType === "market" ? (trade as MarketOrder).originalQuantity.value : trade.quantity.value;

    const executionShape = activeChart
      .createExecutionShape()
      .setTooltip(
        intl.formatMessage(
          defineMessage({ defaultMessage: "{side} {orderTypeText} | Fill Qty: {qty} | Price: {price}" }),
          {
            side,
            orderTypeText,
            qty,
            price: priceFormat.format(Number(fillPrice)),
          }
        )
      )
      .setArrowColor(color)
      .setDirection(trade.side === ORDER_SIDES.BUY ? "buy" : "sell")
      .setTime(trade.updated / 1000)
      .setArrowHeight(10)
      .setPrice(Number(fillPrice));

    if (!historicalTradeShapes.find(o => o.id === trade.hashid)) {
      historicalTradeShapes.push({
        id: trade.hashid,
        remove: () => {
          executionShape.remove();
        },
      });
    }
  });
};

export const getPairDetailLastPrice = (
  allPairDetails: TradeState["allPairDetails"],
  tradingPair,
  websocketLastPrice: string,
  historicalPrices: HistoricalPrices
) => {
  const pairDetail = allPairDetails.data.find(item => item.symbol === tradingPair.symbol);

  const lastPrice = websocketLastPrice ?? historicalPrices?.last_price ?? pairDetail?.lastTradePrice;

  return { pairDetail, lastPrice };
};

export enum SideText {
  Sell = "Sell",
  Buy = "Buy",
}

const getPreviewBaseText = (intl: IntlShape, s: SideText): string => {
  return intl.formatMessage(defineMessage({ defaultMessage: "Preview - {side} Limit @" }), {
    side:
      s === SideText.Buy
        ? intl.formatMessage({ defaultMessage: "Buy" })
        : intl.formatMessage({ defaultMessage: "Sell" }),
  });
};

export const handleLimitOrderPreview = (
  activeChart: IChartWidgetApi,
  priceFormat: Intl.NumberFormat,
  intl: IntlShape,
  side: SideText,
  price: number,
  symbol: string,
  onCancel: () => void
): { clearListener: () => void } => {
  const initialColor = side === SideText.Sell ? sellColor : buyColor;

  emitter.emit(TV_LIMIT_ORDER_PREVIEW_TICKET, { pair: symbol, type: SET_LIMIT_ORDER_TYPE });
  emitter.emit(TV_LIMIT_ORDER_PREVIEW_TICKET, {
    value: side === SideText.Buy ? ORDER_SIDES.BUY : ORDER_SIDES.SELL,
    pair: symbol,
    type: SET_LIMIT_ORDER_SIDE,
  });

  const previewLine = activeChart.createOrderLine({ disableUndo: true });

  let baseText = getPreviewBaseText(intl, side);

  const listener = emitter.addListener(TV_LIMIT_ORDER_PREVIEW_CHART, (payload: TradingViewChartEvents) => {
    const { pair, type } = payload;

    if (pair === symbol) {
      switch (type) {
        case SET_LIMIT_ORDER_QUANTITY:
          track(SET_LIMIT_QUANTITY.name, { [SET_LIMIT_QUANTITY.properties.VALUE]: payload.value });
          previewLine.setQuantity(payload.value);
          break;
        case SET_LIMIT_ORDER_PRICE:
          track(SET_LIMIT_PRICE.name, { [SET_LIMIT_PRICE.properties.VALUE]: payload.value });
          previewLine.setText(`${baseText} ${priceFormat.format(payload.value)}`).setPrice(payload.value);
          break;
        case UPDATE_LIMIT_ORDER_SIDE:
          track(SET_LIMIT_SIDE.name, { [SET_LIMIT_SIDE.properties.VALUE]: payload.value });
          baseText = getPreviewBaseText(intl, payload.value === ORDER_SIDES.BUY ? SideText.Buy : SideText.Sell);

          const updatedColor = payload.value === ORDER_SIDES.BUY ? buyColor : sellColor;

          previewLine
            .setText(`${baseText} ${priceFormat.format(previewLine.getPrice())}`)
            .setLineColor(updatedColor)
            .setBodyBorderColor(updatedColor)
            .setQuantityBackgroundColor(updatedColor)
            .setQuantityBorderColor(updatedColor)
            .setCancelButtonBorderColor(updatedColor);
          break;
        case DESTROY_LIMIT_ORDER_PREVIEW:
          if ((previewLine as IOrderLineAdapter & { _line: { _model?: string } })._line?._model) {
            previewLine.remove();
          }
          onCancel();
          listener.remove();
          break;
      }
    }
  });

  track(SET_LIMIT_PREVIEW.name);
  previewLine
    .setText(`${baseText} ${priceFormat.format(price)}`)
    .setPrice(price)
    .onCancel(null, () => {
      track(REMOVE_LIMIT_PREVIEW.name);
      previewLine.remove();
      onCancel();
      listener.remove();
    })
    .onMove(function () {
      const updatedPrice = this.getPrice();

      this.setText(`${baseText} ${priceFormat.format(updatedPrice)}`);

      emitter.emit(TV_LIMIT_ORDER_PREVIEW_TICKET, {
        value: updatedPrice,
        pair: symbol,
        type: SET_LIMIT_ORDER_PRICE_INPUT,
      });
    })
    .setLineStyle(2)
    .setCancelTooltip(intl.formatMessage({ defaultMessage: "Cancel" }))
    .setLineColor(initialColor)
    .setBodyBorderColor(initialColor)
    .setQuantityBackgroundColor(initialColor)
    .setQuantityBorderColor(initialColor)
    .setCancelButtonBorderColor(initialColor)
    .setBodyBackgroundColor(CHART_BACKGROUND_COLOR)
    .setCancelButtonBackgroundColor(CHART_BACKGROUND_COLOR)
    .setBodyTextColor(textColor)
    .setCancelButtonIconColor(textColor)
    .setLineLength(75, `percentage`);

  emitter.emit(TV_LIMIT_ORDER_PREVIEW_TICKET, { pair: symbol, type: GET_LIMIT_ORDER_QUANTITY });
  emitter.emit(TV_LIMIT_ORDER_PREVIEW_TICKET, { value: price, pair: symbol, type: SET_LIMIT_ORDER_PRICE_INPUT });

  const clearListener = () => {
    if ((previewLine as IOrderLineAdapter & { _line: { _model?: string } })._line?._model) {
      previewLine.remove();
    }
    onCancel();
    listener.remove();
  };

  return {
    clearListener,
  };
};

export const generateLimitOrderPreviewHandler = (
  self: Chart,
  priceFormat: Intl.NumberFormat,
  side: SideText,
  price: number
) => {
  const chart = self.state.tvWidget.activeChart();
  self.limitOrderPreviewLines.forEach(l => l.remove());
  self.limitOrderPreviewLines = [];

  const { clearListener } = handleLimitOrderPreview(
    chart,
    priceFormat,
    self.props.intl,
    side,
    price,
    self.props.tradingPair.symbol,
    () => {
      self.limitOrderPreviewLines = [];
    }
  );

  self.limitOrderPreviewLines.push({
    remove: () => {
      clearListener();
    },
  });
};

export const getContextMenuText = (intl: IntlShape, side: SideText, price: string) =>
  intl.formatMessage(defineMessage({ defaultMessage: "{side} Limit @ {price}" }), {
    side:
      side === SideText.Buy
        ? intl.formatMessage({ defaultMessage: "Buy" })
        : intl.formatMessage({ defaultMessage: "Sell" }),
    price,
  });

export const itemsProcessorHandler = (
  items: readonly IActionVariant[],
  params: CreateContextMenuParams,
  actionsFactory: ActionsFactory,
  self: Chart,
  priceFormat: Intl.NumberFormat
) => {
  if (params.menuName !== "CrosshairMenuView") return Promise.resolve(items);

  const { allPairDetails, tradingPair, websocketLastPrice, historicalPrices } = self.props;

  const { pairDetail, lastPrice } = getPairDetailLastPrice(
    allPairDetails,
    tradingPair,
    websocketLastPrice,
    historicalPrices
  );

  if (!pairDetail?.limitOrderOpen) return Promise.resolve(items);

  const drawHorizontalLineAction = items[0];
  const activePriceText = (drawHorizontalLineAction as IActionVariant & { _options: { label: string } })._options.label
    .split(" ")
    .pop();
  const activePrice = parseFloat(activePriceText.replace(/,/g, ""));

  const { intl } = self.props;

  const side = activePrice > Number(lastPrice) ? SideText.Sell : SideText.Buy;

  const newItem = actionsFactory.createAction({
    actionId: `Custom-Limit-Order` as ActionId,
    label: getContextMenuText(intl, side, priceFormat.format(activePrice)),
    onExecute: () => {
      generateLimitOrderPreviewHandler(self, priceFormat, side, activePrice);
    },
  });

  return Promise.resolve([drawHorizontalLineAction, newItem]);
};

export const initTVLimitOrderPreviewListener = (
  symbol: string,
  quantityRef: MutableRefObject<InputNumberT>,
  setOrderTypeRef: MutableRefObject<TicketParentProps["setOrderType"]>,
  setSideRef: MutableRefObject<TicketParentProps["setSide"]>,
  setPriceRef: MutableRefObject<TicketParentProps["setPrice"]>
) => {
  const listener: EmitterSubscription = emitter.addListener(
    TV_LIMIT_ORDER_PREVIEW_TICKET,
    (payload: TradingViewChartTicketEvents) => {
      const { pair, type } = payload;

      if (pair === symbol) {
        switch (type) {
          case SET_LIMIT_ORDER_TYPE:
            setOrderTypeRef.current(ORDER_TYPES.LIMIT);
            break;
          case SET_LIMIT_ORDER_SIDE:
            setSideRef.current(payload.value);
            break;
          case GET_LIMIT_ORDER_QUANTITY:
            emitter.emit(TV_LIMIT_ORDER_PREVIEW_CHART, {
              value: quantityRef.current.value,
              pair: symbol,
              type: SET_LIMIT_ORDER_QUANTITY,
            });
            break;
          case SET_LIMIT_ORDER_PRICE_INPUT:
            setPriceRef.current(payload.value, false);
            break;
        }
      }
    }
  );

  return listener;
};

export const useTVLimitOrderPreviewTicket = ({
  symbol,
  quantity,
  setPrice,
  setOrderType,
  setSide,
}: Pick<TicketParentProps, "quantity" | "setPrice" | "setOrderType" | "setSide"> & {
  symbol: TicketParentProps["tradingPair"]["symbol"];
}) => {
  // Note we use refs to keep inputs updated without causing the listener to be re-initialized in useEffect with dependency changes
  const quantityRef = useRef<InputNumberT>(quantity);
  const setPriceRef = useRef<TicketParentProps["setPrice"]>(setPrice);
  const setOrderTypeRef = useRef<TicketParentProps["setOrderType"]>(setOrderType);
  const setSideRef = useRef<TicketParentProps["setSide"]>(setSide);

  // Need to also set current directly to capture updated state
  quantityRef.current = quantity;
  setPriceRef.current = setPrice;
  setOrderTypeRef.current = setOrderType;
  setSideRef.current = setSide;

  const tradingPlatformEnabled = optimizelyClient.isFeatureEnabled(
    OPTIMIZELY_FEATURE_FLAGS.WEB_TRADING_VIEW_TRADING_PLATFORM
  );

  useEffectOnce(() => {
    let tvLimitOrderPreviewListener: EmitterSubscription | null = null;
    if (tradingPlatformEnabled) {
      tvLimitOrderPreviewListener = initTVLimitOrderPreviewListener(
        symbol,
        quantityRef,
        setOrderTypeRef,
        setSideRef,
        setPriceRef
      );
    }

    return () => {
      if (tvLimitOrderPreviewListener) {
        tvLimitOrderPreviewListener.remove();
      }
    };
  });
};

export const getTradingViewIframe = (pair: string): HTMLIFrameElement => {
  return document.querySelector(`[data-chart-pair="${pair}-chart"]`).querySelector(`iframe[id^="tradingview_"]`);
};

export const getOrderBookChartParent = (pair: string): HTMLElement | null => {
  const orderBookChartContainer = document.querySelector(`[data-chart-pair="${pair}-chart"]`);

  if (orderBookChartContainer) return orderBookChartContainer.closest(`.flexlayout__tab`);

  return null;
};

export const getOrderBookChartParentRect = (pair: string): DOMRect => {
  const orderBookParent = getOrderBookChartParent(pair);

  const roundedRect = {};

  if (orderBookParent) {
    const rawRect = orderBookParent.getBoundingClientRect();

    for (const key in rawRect) {
      if (key !== "toJSON") {
        roundedRect[key] = Math.round(rawRect[key]);
      }
    }
  }

  return roundedRect as DOMRect;
};

export const getChartPriceAxisWidth = (pair: string): number | null => {
  const iframe = getTradingViewIframe(pair);

  if (iframe) {
    // using getElementsByClassName is more performant when querying large DOM objects
    const results = iframe.contentWindow.document.body?.getElementsByClassName(
      `price-axis`
    ) as HTMLCollectionOf<HTMLDivElement>;

    if (results?.length === 1) {
      return parseInt(results[0].style.width);
    }
  }

  return null;
};

export const initOrderFormResize = (
  containerRef: React.MutableRefObject<HTMLElement>,
  bottomRightResizer: React.MutableRefObject<HTMLDivElement>,
  setResizing: React.Dispatch<React.SetStateAction<boolean>>
) => {
  let initialWidth = 0;
  let initialHeight = 0;
  let initialMouseX = 0;
  let initialMouseY = 0;

  const minWidth = 260;
  const minHeight = 450;
  const maxWidth = window.screen.width * 0.4;
  const maxHeight = window.screen.height * 0.6;

  const container = containerRef.current as HTMLDivElement;
  const activeResizer = bottomRightResizer.current;

  const resize = (e: MouseEvent) => {
    const width = Math.round(initialWidth + (e.pageX - initialMouseX));
    const height = Math.round(initialHeight + (e.pageY - initialMouseY));

    if (width > minWidth && width < maxWidth) {
      container.style.width = `${width}px`;
    }

    if (height > minHeight && height < maxHeight) {
      container.style.height = `${height}px`;
    }
  };

  const handleMouseDown = (e: MouseEvent) => {
    e.preventDefault();

    setResizing(true);

    const elementComputedStyles = getComputedStyle(container, null);
    initialWidth = parseFloat(elementComputedStyles.getPropertyValue("width").replace("px", ""));
    initialHeight = parseFloat(elementComputedStyles.getPropertyValue("height").replace("px", ""));

    initialMouseX = e.pageX;
    initialMouseY = e.pageY;

    window.addEventListener("mousemove", resize);

    function handleMouseUp() {
      setResizing(false);
      window.removeEventListener("mousemove", resize);
      window.removeEventListener("mouseup", handleMouseUp);
    }

    window.addEventListener("mouseup", handleMouseUp);
  };

  activeResizer.addEventListener("mousedown", handleMouseDown);

  return {
    clearListener: () => {
      activeResizer.removeEventListener("mousedown", handleMouseDown);
    },
  };
};
